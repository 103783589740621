<template>
  <div>
    <div v-if="isChild == 0">
      <bread-crumb :crumbData="['宣传素材']" :chooseCrumbData="1"></bread-crumb>
      <search-case
        :searchData="searchData"
        :chooseSearch="3"
        @inquireBtnClick="inquireBtnClick"
      ></search-case>

      <div
        style="
          padding: 10px 20px;
          border-bottom: 1px solid #eee;
          display: flex;
          align-items: center;
        "
      >
        <div style="margin-right: 5px">
          <el-button type="primary" size="mini" @click="isChild = 1">
            <div style="display: flex; align-items: center; font-size: 14px">
              <img
                style="width: 16px; height: 16px"
                :src="require('@/assets/iconImg/logo.png')"
              />
              <span style="margin-left: 3px">新增素材</span>
            </div>
          </el-button>
        </div>
      </div>

      <div style="padding: 20px">
        <el-table
          ref="multipleTable"
          :data="tableData"
          tooltip-effect="dark"
          border
          @selection-change="handleSelectionChange"
          style="width: 100%"
        >
          <el-table-column type="selection" width="44"> </el-table-column>
          <el-table-column
            prop="serial_number"
            sortable
            label="序号"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            sortable
            label="创建日期"
          ></el-table-column>
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <i class="el-icon-edit poiner" @click="handleEdit(scope.row)"></i>
              <span style="margin: 0 10px"></span>
              <i
                class="el-icon-delete poiner"
                @click="handleDelete(scope.row)"
              ></i>
            </template>
          </el-table-column>
        </el-table>
        <div class="tableBottom">
          <span style=""
            >显示第&nbsp;{{
              tableData.length == 0 ? 0 : (page - 1) * length + 1
            }}&nbsp;至&nbsp;{{
              (page - 1) * length + tableData.length
            }}&nbsp;项结果，共&nbsp;{{ tableFinshNum }}&nbsp;项</span
          >

          <div
            style="
              display: flex;
              justify-content: flex-end;
              align-items: center;
            "
          >
            <el-button @click="homePage">首页</el-button>
            <el-button @click="upPage">上页</el-button>
            <el-pagination
              background
              layout=" pager,slot"
              @current-change="currentChange"
              :total="tableFinshNum"
              :current-page="page"
            ></el-pagination>
            <el-button @click="nextPage">下页</el-button>
            <el-button @click="endPage">末页</el-button>
          </div>
        </div>
      </div>
    </div>

    <add-group v-if="isChild == 1" @close="onClose" :data="data"></add-group>
  </div>
</template>
<script>
import addGroup from "./childrens/addGroup.vue";
import { getTime } from "../../../../until/getTime";
export default {
  components: {
    addGroup,
  },
  data() {
    return {
      searchData: {
        date: "",
        optionLongName1: "模版类型",
        optionLongNamees1: ["海报", "商品", "招商", "证书", "叫水"],
        isInquireValue: true,
        inquirePlaceholder: "输入要查询的内容",
        isInquireBtn: true,
      },

      tableData: [],
      multipleSelection: [],
      isResult: false,
      tableFinshNum: 0,

      isChild: 0,
      page: 1,
      length: 10,
      commercial_id: "",
      data: "",
    };
  },
  mounted() {
    this.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
    this.getTabList();
  },
  computed: {},
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    getTabList() {
      this.$http
        .post("/content/publicity_material/lst", {
          commercial_id: this.commercial_id,
          currentPage: this.page,
          currentLength: this.length,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    inquireBtnClick(data) {
      console.log(data);
      this.$http
        .post("/content/publicity_material/search", {
          commercial_id: this.commercial_id,
          firstTime: getTime(data.dateRange[0]),
          lastTime: getTime(data.dateRange[1]),
          type: data.optionLongName1,
          search: data.inquireValue,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.tableFinshNum = res.data.count;
        });
    },
    handleEdit(row) {
      this.data = row;
      this.isChild = 1;
    },
    handleDelete(row) {
      this.$confirm("此操作将永久删除该文件, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http
            .post("/content/publicity_material/del", {
              id: row.id,
            })
            .then((res) => {
              if (res.data.status == "success") {
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
                this.getTabList();
                return;
              }
              this.$message.error(res.data.msg);
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    currentChange(page) {
      this.page = page;
    },
    homePage() {
      this.page = 1;
    },
    upPage() {
      if (this.page == 1) return;
      this.page -= 1;
    },
    nextPage() {
      if (this.page >= Math.ceil(this.tableFinshNum / this.length)) return;
      this.page += 1;
    },
    endPage() {
      this.page = Math.ceil(this.tableFinshNum / this.length);
    },
    onClose(data) {
      this.isChild = data;
      this.getTabList();
      this.data = "";
    },
  },
  watch: {
    page: {
      handler() {
        this.getTabList();
      },
    },
  },
};
</script>
<style scoped lang="less">
.tableBottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #666;
  font-size: 14px;
}
/deep/ .cell {
  padding: 0 !important;
}
</style>
