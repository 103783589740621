<template>
  <div>
    <bread-crumb
      :crumbData="['宣传素材', '新增']"
      :chooseCrumbData="2"
      @close="onClose"
    ></bread-crumb>

    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="100px"
      class="demo-ruleForm"
      style="padding: 50px"
    >
      <el-form-item label="序号：">
        <el-input v-model="ruleForm.serial_number"></el-input>
      </el-form-item>
      <el-form-item label="标题：" prop="title">
        <el-input v-model="ruleForm.title"></el-input>
      </el-form-item>
      <el-form-item label="模版类型：" prop="template_type">
        <el-radio-group v-model="ruleForm.template_type">
          <el-radio v-for="item in types" :key="item" :label="item"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="关联id：">
        <el-input v-model="ruleForm.relevance_id"></el-input>
      </el-form-item>
      <el-form-item label="转发图" prop="transmit_image">
        <el-upload
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="httpRequest"
        >
          <img
            v-if="ruleForm.transmit_image"
            :src="'http://192.168.3.3' + ruleForm.transmit_image"
            class="avatar"
          />
          <el-button size="small" type="primary" class="uploadBtu"
            >点击上传</el-button
          >
        </el-upload>
      </el-form-item>
      <el-form-item label="海报图" prop="poster_drawing">
        <el-upload
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="httpRequest1"
        >
          <img
            v-if="ruleForm.poster_drawing"
            :src="'http://192.168.3.3' + ruleForm.poster_drawing"
            class="avatar"
          />
          <el-button size="small" type="primary" class="uploadBtu"
            >点击上传</el-button
          >
        </el-upload>
      </el-form-item>
      <el-form-item label="二维码：">
        <div style="display: flex; align-items: flex-start">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-right: 10px;
            "
          >
            <span>X：</span>
            <el-input v-model="ruleForm.qr_code.x"></el-input>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            "
          >
            <span>Y：</span>
            <el-input v-model="ruleForm.qr_code.y"></el-input>
          </div>
        </div>
      </el-form-item>
      <!-- <el-form-item label="头像：">
        <div style="display: flex; align-items: flex-start">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-right: 10px;
            "
          >
            <span>X：</span>
            <el-input v-model="ruleForm.headPhotoX"></el-input>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            "
          >
            <span>Y：</span>
            <el-input v-model="ruleForm.headPhotoY"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="昵称：">
        <div style="display: flex; align-items: flex-start">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-right: 10px;
            "
          >
            <span>X：</span>
            <el-input v-model="ruleForm.nickNameX"></el-input>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            "
          >
            <span>Y：</span>
            <el-input v-model="ruleForm.nickNameY"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="角色：">
        <div style="display: flex; align-items: flex-start">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-right: 10px;
            "
          >
            <span>X：</span>
            <el-input v-model="ruleForm.roleX"></el-input>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            "
          >
            <span>Y：</span>
            <el-input v-model="ruleForm.roleY"></el-input>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="手机：">
        <div style="display: flex; align-items: flex-start">
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
              margin-right: 10px;
            "
          >
            <span>X：</span>
            <el-input v-model="ruleForm.phoneX"></el-input>
          </div>
          <div
            style="
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: flex-start;
            "
          >
            <span>Y：</span>
            <el-input v-model="ruleForm.phoneY"></el-input>
          </div>
        </div>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  props: ["data"],
  data() {
    return {
      types: ["海报", "商品", "招商", "证书", "叫水"],
      ruleForm: {
        serial_number: "",
        title: "",
        template_type: "",
        transmit_image: "",
        poster_drawing: "",
        relevance_id: "",
        qr_code: {
          x: "46",
          y: "592",
        },
        id: 0,
        commercial_id: "",
      },
      rules: {
        title: [{ required: true, message: "必填字段", trigger: "blur" }],
        template_type: [
          { required: true, message: "必填字段", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    if (this.$props.data) {
      this.ruleForm = this.$props.data;
    }
    this.ruleForm.commercial_id =
      localStorage.getItem("commercial_id") ||
      sessionStorage.getItem("commercial_id");
  },
  computed: {},
  methods: {
    onClose(data) {
      this.$emit("close", data);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$http
            .post("/content/publicity_material/save", this.ruleForm)
            .then((res) => {
              if (res.data.status == "success") {
                this.onClose(0);
                this.$message({
                  type: "success",
                  message: res.data.msg,
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    httpRequest(file) {
      this.getBase64(file.file).then((res) => {
        this.$http
          .post("uploads_images/up", this.$qs.stringify({ file: res }))
          .then((ress) => {
            this.ruleForm.transmit_image = ress.data.path;
          });
      });
    },
    httpRequest1(file) {
      this.getBase64(file.file).then((res) => {
        this.$http
          .post("uploads_images/up", this.$qs.stringify({ file: res }))
          .then((ress) => {
            this.ruleForm.poster_drawing = ress.data.path;
          });
      });
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
  },
};
</script>
<style scoped lang="less">
.avatar {
  width: 78px;
  height: 78px;
  display: block;
  padding: 5px;
  border: 1px solid #dddddd;
  pointer-events: none !important;
}

/deep/ .avatar-uploader .el-upload {
  border: 0;
  position: relative;
  overflow: hidden;
  pointer-events: none;
}

.uploadBtu {
  pointer-events: auto !important;
  margin-top: 15px !important;
}
</style>
